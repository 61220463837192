import React, { ReactElement, useState } from 'react';
import {
	ConnectorServiceCard,
	ConnectorUsageOptions
} from 'src/components/connectors';
import {
	ConnectorCard,
	ConnectorStatus
} from 'src/components/connectors/ConnectorCard';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { DIConnector, DISubscription } from 'src/types/dataTypes';
import onedrive from '../../static/onedrive.png';

interface Props extends ConnectorServiceCard {
	title?: string;
	subtitle?:string;
	usage: ConnectorUsageOptions;
	onStatus?,
	render? : string;
	subscription
}

function OneDriveConnectorCard({
	title = 'OneDrive',
	subtitle,
	usage,
	onStatus,
	render,
	subscription
}: Props): ReactElement {
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	if (!auth || subscription == null) {
		return (
			<ConnectorCard
				logoSrc={onedrive}
				isLoading={true}
				title={title}
			></ConnectorCard>
		);
	}

	return usage === 'personal' ? (
		<PersonalOneDriveCard
			title={title}
			subtitle={subtitle}
			accountId={auth.accountDetails.account.id}
			subscription={subscription}
			auth={auth}
			onStatus={onStatus}
			render={render}
		/>
	) : (
		<CompanyOneDriveCard
			title={title}
			subtitle={subtitle}
			subscription={subscription}
			accountId={auth.accountDetails.account.id}
			auth={auth}
			onStatus={onStatus}
			render={render}
		/>
	);
}

function PersonalOneDriveCard({ accountId, title, subtitle = 'My Account',subscription, auth,render,onStatus }) {
	const { api } = useAppToolchain();
	const [createConnector, setCreateConnector] = useState(false);
	const [doDisableClick, setDoDisableClick] = useState(false);
	const [disable, setDoDisable] = useState(false);
	const [isModifyAllowed, setIsModifyAllowed] = useState(false);
	const [connectorStatus, connectors] = useApiRequest<DIConnector[]>(
		(signal) =>
			!!subscription &&
			api.getAccountConnectorsForSubscription(accountId,subscription.id, 'OneDriveConnector', {
				signal,
			}),
		[subscription],
		() => {
			if( connectors && connectors[0]){
				setDoDisable( connectors[0].disabled );
				if(onStatus){
					onStatus(true)
				}
			}
				
		}
	);

	
	useApiRequest(
		connectors && connectors.length >0 &&  connectors[0].id && ((signal) => api.getAccountAssignment(auth.accountDetails.account.id, {obj : "/connectors/"+connectors[0].id, operation:"modify"})),
		[connectors],
		([status, resp]) => {
			let currentUserId = auth.user.id;
			let assignments = resp.data
			assignments.forEach( ass => {
				if( ass.subject == currentUserId ){
					setIsModifyAllowed(true);
				}
			})
		}
	);

	const [creationStatus, createdConnector] = useApiRequest<DIConnector>(
		(signal) =>
			createConnector &&
			api.addConnectorToAccountSubscriptions(
				accountId,
				subscription.id,
				{
					type: 'OneDriveConnector',
					paused: false,
				},
				{ signal },
			),
		[createConnector, accountId, subscription.id],
		([creationStatus, createdConnector, err]) => {
			if (createdConnector?.provider?.authorizeUrl) {
				api.getAccessToken().then((token) => {
					const loc = `${createdConnector?.provider?.authorizeUrl}?access_token=${token}`;
					window.location.href = loc;
				});
			}
		},
	);

	

	// Three options: no connector, connector, but unauthorized, authorized connector
	let status: ConnectorStatus = 'UNKNOWN';
	if (connectorStatus === 'RESOLVED' && connectors.length) {
		if (connectors[0]?.provider?.consented) {
			status = 'AUTHORIZED';
		} else {
			status = 'UNAUTHORIZED';
		}
	} else {
		status = 'NOT CONNECTED';
	}

	function handleClick() {
		switch (status) {
			case 'NOT CONNECTED':
				setCreateConnector(true);
				break;
			case 'UNAUTHORIZED':
			case 'AUTHORIZED':
				api.getAccessToken().then((token) => {
					const loc = `${connectors[0]?.provider?.authorizeUrl}?access_token=${token}`;
					window.location.href = loc;
				});
			default:
				break;
		}
	}
	const { status: disableStatus } = useApiRequest<boolean>(
		(signal) =>
			doDisableClick &&
			api.patchConnectorForAccount(accountId, connectors[0].id,[{
				path: '/disabled',
				op: 'replace',
				value: disable,
			}]),
		[doDisableClick],
		() => {
			setDoDisableClick(false);
		},
		true,
	);


	function handleDisable(e,checked){
		setDoDisable(!checked);
		setDoDisableClick(true)
	}

	return (
		<ConnectorCard
			logoSrc={onedrive}
			isLoading={
				connectorStatus === 'RUNNING' || creationStatus === 'RUNNING' || disableStatus == 'RUNNING'
			}
			title={title}
			subtitle={subtitle}
			status={status}
			isDisabled={disable}
			onDisableClick={handleDisable}
			onSubmitButtonClick={handleClick}
			canModify={isModifyAllowed}
			render={render}
		></ConnectorCard>
	);
}

function CompanyOneDriveCard({
	subscription,
	accountId,
	title,
	subtitle = 'Entire Tenant',
	auth,
	onStatus,
	render
}: {
	subscription: DISubscription,
	accountId: string,
	title: string,
	subtitle?:string,
	auth:any,
	onStatus?,
	render:string
}) {
	const { api } = useAppToolchain();
	const [createConnector, setCreateConnector] = useState(false);
	const [doDisableClick, setDoDisableClick] = useState(false);
	const [disable, setDoDisable] = useState(false);
	const [connectors, setConnectors] = useState<DIConnector[]>([]);
	const [isModifyAllowed, setIsModifyAllowed] = useState(false);
	const { status: connectorStatus } = useApiRequest<DIConnector[]>(
		(signal) =>
			!!subscription &&
			api.getAccountConnectors(accountId, 'OneDriveDiscoveryConnector', {
				signal,
			}),
		[subscription],
		({ result: accountConnectors }) =>{
			if( accountConnectors && accountConnectors[0]){
				setDoDisable(accountConnectors[0].disabled)
				if(onStatus){
					onStatus(true)
				}
			}
			setConnectors(accountConnectors)
		},
		true,
	);
	useApiRequest(
		connectors && connectors.length >0 &&  connectors[0].id && ((signal) => api.getAccountAssignment(auth.accountDetails.account.id, {obj : "/connectors/"+connectors[0].id, operation:"modify"})),
		[connectors],
		([status, resp]) => {
			let currentUserId = auth.user.id;
			let assignments = resp.data
			assignments.forEach( ass => {
				if( ass.subject == currentUserId ){
					setIsModifyAllowed(true);
				}
			})
		}
	);

	const { status: creationStatus, error: createdError } =
		useApiRequest<DIConnector>(
			createConnector &&
				((signal) =>
					api.addConnectorToAccountSubscriptions(
						accountId,
						subscription.id,
						{
							type: 'OneDriveDiscoveryConnector',
							paused: false,
						},
						{ signal },
					)),
			[createConnector, accountId, subscription.id],
			({ result: createdConnector, error }) => {
				setCreateConnector(false);
				if (error) return;

				if (createdConnector?.provider?.authorizeUrl) {
					api.getAccessToken().then((token) => {
						const loc = `${createdConnector?.provider?.authorizeUrl}?access_token=${token}&redirectUrl=${window.location.href}`;
						window.location.href = loc;
					});
				}

				setConnectors([...connectors, createdConnector]);
			},
			true,
		);

	const { status: disableStatus } = useApiRequest<boolean>(
		(signal) =>
			doDisableClick &&
			api.patchConnectorForAccount(accountId, connectors[0].id,[{
				path: '/disabled',
				op: 'replace',
				value: disable,
			}]),
		[doDisableClick],
		() => {
			setDoDisableClick(false);
		},
		true,
	);

	// Three options: no connector, connector, but unauthorized, authorized connector
	let status: ConnectorStatus = 'UNKNOWN';
	if (connectorStatus === 'RESOLVED' && connectors.length) {
		
		if (connectors[0]?.provider.consented) {
			status = 'AUTHORIZED';
		} else {
			status = 'UNAUTHORIZED';
		}
	} else {
		status = 'NOT CONNECTED';
	}

	function handleClick() {
		switch (status) {
			case 'NOT CONNECTED':
				setCreateConnector(true);
				break;
			case 'UNAUTHORIZED':
			case 'AUTHORIZED':
				api.getAccessToken().then((token) => {
					const loc = `${connectors[0]?.provider?.authorizeUrl}?access_token=${token}`;
					window.location.href = loc;
				});
			default:
				break;
		}
	}

	function handleDisableClick(e,checked) {
		setDoDisable( !checked );
		setDoDisableClick(true);
	}

	return (
		<ConnectorCard
			logoSrc={onedrive}
			error={createdError?.message && 'Error creating connector'}
			isLoading={
				creationStatus === 'RUNNING' || disableStatus === 'RUNNING'
			}
			title={title}
			subtitle={subtitle}
			status={status}
			onSubmitButtonClick={handleClick}
			onDisableClick={handleDisableClick}
			isDisabled={disable}
			canModify={isModifyAllowed}
			render={render}
		></ConnectorCard>
	);
}

export default OneDriveConnectorCard;
