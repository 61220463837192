import React from 'react';
import {
	DataTable,
	DataTableColumn,
	Typography,
	Checkbox,
	IconButton,
} from '@barracuda-internal/bds-core';
import { GridNoRecords} from '@progress/kendo-react-grid';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { TableCell } from '@material-ui/core';
import { TagRendererCell, UserRendererCell, StatusRenderer } from './Renderer';
import TablePagination from 'src/components/TablePagination';
import moment from 'moment';
import useStyles from 'src/styles/di-theme';
import pull from 'lodash/pull';
import { CaseCloudTypeInfo } from 'src/components/cases/CloudTypeCell';
import { DefaultColumnHeader } from 'src/components/Renderer';
import noViolations from 'src/static/no_new_violations.svg';
import noSearch from 'src/static/no_search_violations.svg';
import classNames from 'classnames';
import TableChart from '@mui/icons-material/TableChart';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ColumnSelector from 'src/components/ColumnSelector';
import Cookies from 'js-cookie';
import TooltipColumn from 'src/components/TooltipColumn';
export const RowContext = React.createContext(() => {
	/** noop */
});
export default function GridPanel({
	onSelectFeed,
	selectedFeed,
	feeds,
	loading,
	pageSize,
	page,
	total,
	onPageChange,
	sort,
	sortDir,
	onSortChange,
	selectedState,
	setSelectedState,
	width,
	tab,
	searchText,
	selectedFilters,
	setShowShimmer
}) {
	const classes = useStyles();
	const [openColumnSelector, setOpenColumnSelector] = React.useState(false);
	const onNewSelectionChange = (checked, feedId) => {
		if (checked) {
			setSelectedState(selectedState.push(feedId));
		} else {
			setSelectedState(pull(selectedState, feedId));
		}
		setSelectedState([].concat(selectedState));
	};

	const allColumns = tab !== 'Resolved' ? [
		{
			value: 'File',
			disable: true,
		},
		{
			value: 'Last Detected',
			disable: false,
		},
		{
			value: 'Owner/Creator',
			disable: false,
		},
		{
			value: 'Violations',
			disable: false,
		},
		{
			value: 'Sharing',
			disable: false,
		},
		{
			value: 'Classifiers',
			disable: false,
		},
	]: [
		{
			value: 'File',
			disable: true,
		},
		{
			value: 'Resolved',
			disable: false,
		},
		{
			value: 'Resolved By',
			disable: false,
		},
		{
			value: 'Owner/Creator',
			disable: false,
		},
		{
			value: 'Violations',
			disable: false,
		},
		{
			value: 'Sharing',
			disable: false,
		},
		{
			value: 'Classifiers',
			disable: false,
		},
	];
	const getDefaultColumns = () => {
		let columns = tab !== 'Resolved' ? [
			'File',
			'Platform',
			'Last Detected',
			'Owner/Creator',
			'Violations',
			'Sharing',
			'Classifiers',
		] : [
			'File',
			'Platform',
			'Resolved',
			'Resolved By',
			'Owner/Creator',
			'Violations',
			'Sharing',
			'Classifiers',
		];
		if (Cookies.get('unresolved_incident_columns') != null && tab === 'Unresolved') {
			columns = JSON.parse(Cookies.get('unresolved_incident_columns'));
		}
		if (Cookies.get('inprogress_incident_columns') != null && tab === 'In Progress') {
			columns = JSON.parse(Cookies.get('inprogress_incident_columns'));
		}
		if (Cookies.get('resolved_incident_columns') != null && tab === 'Resolved') {
			columns = JSON.parse(Cookies.get('resolved_incident_columns'));
		}
		return columns;
	}
	
	const [visibleColumns, setVisibleColumns] = React.useState([]);

	React.useEffect(() => {
		setVisibleColumns(getDefaultColumns());
	  }, [tab]);

	const onRowClick = (e: any) => {
		onSelectFeed(e.dataItem);
	};

	const resolvedFeed = feeds[0]?.status !== 'Open' ? true : false;

	const rowRender = (trElement, props) => {
		const feedId = props.dataItem.feedId;
		const trProps: any = {
			style: {
				cursor: resolvedFeed ? 'default' : 'pointer',
			},
		};
		if (selectedFeed != null && feedId == selectedFeed.feedId) {
			trProps.class = trElement.props.className + ' highlight';
		}
		return React.cloneElement(
			trElement,
			{
				...trProps,
			},
			trElement.props.children,
		);
	};

	const CustomLockedCell = (props, children) => {
		const field = props.field || '';
		return (
			<td
				style={props.style} // this applies styles that lock the column at a specific position
				className={props.className} // this adds classes needed for locked columns
				colSpan={props.colSpan}
				role={'gridcell'}
				aria-colindex={props.ariaColumnIndex}
				aria-selected={props.isSelected}
			>
				{children}
			</td>
		);
	};

	return (
		<div className={classes['di_kendo_grid']}>
			<IconButton
				className={classes['setting_button_right']}
				onClick={() => {
					setOpenColumnSelector(true);
				}}
			>
				<TableChart />
			</IconButton>

			{openColumnSelector ? (
				<div className={classes['setting_window']}>
					<ColumnSelector
						allColumns={allColumns}
						selectedColumns={visibleColumns}
						onClose={() => {
							setOpenColumnSelector(false);
						}}
						onSelectionChange={(selection) => {
							if(tab === 'Unresolved') {
								Cookies.set(
									'unresolved_incident_columns',
									JSON.stringify(selection),
								);
							}

							if(tab === 'In Progress') {
								Cookies.set(
									'inprogress_incident_columns',
									JSON.stringify(selection),
								);
							}

							if(tab === 'Resolved') {
								Cookies.set(
									'resolved_incident_columns',
									JSON.stringify(selection),
								);
							}
							
							setVisibleColumns(selection);
						}}
					></ColumnSelector>
				</div>
			) : null}

			<div
				style={{
					maxHeight: 'calc(100vh - 280px)',
					width: width,
					overflow: 'auto',
					marginBottom: 50,
				}}
			>
				{loading ? (
					<div
						style={{
							maxHeight: 'calc(100vh - 300px)',
							height: 'calc(100vh - 320px)',
						}}
					>
						<LoadingIndicator message="Loading ..." />
					</div>
				) : (
					<RowContext.Provider value={onRowClick}>
						<DataTable
							data={feeds}
							onSortChange={(e) => {
								if (e.sort.length > 0) {
									onSortChange(
										e.sort[0].field,
										e.sort[0].dir,
									);
								} else {
									onSortChange('', '');
								}
							}}
							sort={[{ field: sort, dir: sortDir }]}
							sortable={true}
							onRowClick={onRowClick}
							style={{
								border: 0,
							}}
							rowRender={rowRender}
						>
							<GridNoRecords>&nbsp;</GridNoRecords>

							{tab == 'Unresolved' ? (
								<DataTableColumn
									locked={true}
									cell={(props) => {
										let dataItem = props.dataItem;
										return CustomLockedCell(
											props,
											<Checkbox
												size="small"
												color="primary"
												checked={
													selectedState.indexOf(
														dataItem.feedId,
													) > -1
												}
												onChange={(event, checked) => {
													onNewSelectionChange(
														checked,
														dataItem.feedId,
													);
												}}
											/>,
										);
									}}
									field="date_modified"
									filterable={false}
									headerCell={(props) => {
										return (
											<Checkbox
												size="small"
												style={{
													padding: '0px 0px 4px 8px',
												}}
												color="primary"
												checked={
													feeds.length > 0 &&
													feeds.length ==
														selectedState.length
												}
												indeterminate={
													selectedState.length > 0 &&
													selectedState.length !=
														feeds.length
												}
												indeterminateIcon={
													<IndeterminateCheckBoxIcon color="primary" />
												}
												onChange={(event, checked) => {
													if (checked) {
														setSelectedState(
															feeds.map(
																(feed) =>
																	feed.feedId,
															),
														);
													} else {
														setSelectedState([]);
													}
												}}
											/>
										);
									}}
									width="75px"
								/>
							) : null}
							{tab == 'Resolved' || tab == 'In Progress' ? (
								<DataTableColumn
									cell={({ dataItem }) => {
										return StatusRenderer(dataItem, tab);
									}}
									field="cloudType"
									sortable={false}
									headerCell={DefaultColumnHeader}
									title="Action"
									width={200}
								/>
							) : null}
							<DataTableColumn
								locked={true}
								cell={(props) => {
									let dataItem = props.dataItem;
									return CustomLockedCell(
										props,
										<div
											className={
												resolvedFeed ? classes['cursor_default'] : classes['cursor_pointer']
											}
											onClick={() =>
												onSelectFeed(dataItem)
											}
										>
											<Typography
												variant="body1"
												color="textPrimary"
												noWrap
												onClick={() =>
													onSelectFeed(dataItem)
												}
											>
												{dataItem.fileName}
											</Typography>
											<TooltipColumn
												content={
													<Typography
														variant="body1"
														color="textSecondary"
														noWrap
													>
														<CaseCloudTypeInfo
															cloudType={
																dataItem.cloudType
															}
														></CaseCloudTypeInfo>{' '}
														{' > '}{' '}
														{dataItem.filePath.join(
															' > ',
														)}
													</Typography>
												}
											></TooltipColumn>
										</div>,
									);
								}}
								field="file"
								title="File"
								width={320}
							/>

							{(visibleColumns.indexOf('Last Detected') != -1 && tab !== 'Resolved') ? (
								<DataTableColumn
									cell={(props) => {
										let dataItem = props.dataItem;
										return (
											<RowContext.Consumer>
												{(handleRowClick: any) => (
													<TableCell
														onClick={() =>
															handleRowClick({
																dataItem:
																	dataItem,
															})
														}
													>
														<Typography
															variant="body1"
															color="textPrimary"
															noWrap
															component="span"
														>
															{moment(
																dataItem.lastModified,
															).format('MMM, DD')}
														</Typography>
														<Typography
															variant="body1"
															color="textSecondary"
															noWrap
															component="span"
														>
															{moment(
																dataItem.lastModified,
															).format(' LT')}
														</Typography>
													</TableCell>
												)}
											</RowContext.Consumer>
										);
									}}
									field="lastUpdated"
									title={"Last detected"}
									width="160px"
								/>
							) : null}
							{(visibleColumns.indexOf('Resolved') != -1 && tab === 'Resolved') ? (
								<DataTableColumn
									cell={(props) => {
										let dataItem = props.dataItem;
										return (
											<RowContext.Consumer>
												{(handleRowClick: any) => (
													<TableCell
														onClick={() =>
															handleRowClick({
																dataItem:
																	dataItem,
															})
														}
													>
														<Typography
															variant="body1"
															color="textPrimary"
															noWrap
															component="span"
														>
															{moment(
																dataItem.lastModified,
															).format('MMM, DD')}
														</Typography>
														<Typography
															variant="body1"
															color="textSecondary"
															noWrap
															component="span"
														>
															{moment(
																dataItem.lastModified,
															).format(' LT')}
														</Typography>
													</TableCell>
												)}
											</RowContext.Consumer>
										);
									}}
									field="lastUpdated"
									title={"Resolved"}
									width="160px"
								/>
							) : null}
							{(tab == 'Resolved' && visibleColumns.indexOf('Resolved By') != -1 ) ? (
								<DataTableColumn
									cell={({ dataItem }) => {
										return (
											<RowContext.Consumer>
												{() => (
													<td>
														<Typography
															variant="body1"
															className={classNames(classes['p_1'], classes['d_inline'])}
														>
															{dataItem.resolvedBy == null ? '-' : dataItem.resolvedBy}
														</Typography>
													</td>
												)}
											</RowContext.Consumer>
										);
									}}
									field="cloudType"
									sortable={false}
									headerCell={DefaultColumnHeader}
									title="Resolved By"
									width={200}
								/>
							) : null}
							{visibleColumns.indexOf('Owner/Creator') != -1 ? (
								<DataTableColumn
									cell={UserRendererCell}
									className={classes['cursor_pointer']}
									field="owner"
									title="Owner/Creator"
									width="200px"
									headerCell={DefaultColumnHeader}
									sortable={false}
								/>
							) : null}
							{visibleColumns.indexOf('Violations') != -1 ? (
								<DataTableColumn
									cell={({ dataItem }) => (
										<RowContext.Consumer>
											{(handleRowClick: any) => (
												<TableCell
													onClick={() =>
														handleRowClick({
															dataItem: dataItem,
														})
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
														noWrap
														className={classNames(
															classes[
																'text_right'
															],
															classes['pr_2'],
														)}
													>
														{dataItem.violations}
													</Typography>
												</TableCell>
											)}
										</RowContext.Consumer>
									)}
									field="violations"
									title="Violations"
									width="130px"
								/>
							) : null}
							{visibleColumns.indexOf('Sharing') != -1 ? (
								<DataTableColumn
									cell={({ dataItem }) => (
										<RowContext.Consumer>
											{(handleRowClick: any) => (
												<TableCell
													onClick={() =>
														handleRowClick({
															dataItem: dataItem,
														})
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
													>
														{dataItem.sharingLevels.join(
															', ',
														)}
													</Typography>
												</TableCell>
											)}
										</RowContext.Consumer>
									)}
									field="sharingLevels"
									sortable={false}
									title="Sharing"
									width="150px"
								/>
							) : null}
							{visibleColumns.indexOf('Classifiers') != -1 ? (
								<DataTableColumn
									cell={TagRendererCell}
									field="tags"
									sortable={false}
									title="Classifiers"
									width="500px"
								/>
							) : null}
						</DataTable>
					</RowContext.Provider>
				)}
			</div>
			{total == 0 && !loading ? (
				<>
					<div
						style={{
							position: 'fixed',
							zIndex: 2,
							top: 400,
							width: '100%',
							textAlign: 'center',
						}}
					>
						{searchText != '' || selectedFilters.length > 0 ? (
							<div>
								<Typography variant="h3" color="textPrimary">
									{' '}
									No result found{' '}
								</Typography>
								<Typography color="textSecondary">
									Try different search
								</Typography>
								<img
									src={noSearch}
									alt="No Search"
									width="300px"
								/>
							</div>
						) : (
							<div>
								<Typography variant="h3" color="textPrimary">No New Violations</Typography>

								<img
									src={noViolations}
									alt="No Violations"
									width="300px"
								/>
							</div>
						)}

						
					</div>
				</>
			) : null}

			{total != 0 && (
				<div
					className={classNames(
						classes['mt_2'],
						classes['bottom_pagination'],
					)}
					style={{ width: width }}
				>
					<TablePagination
						onPageChange={onPageChange}
						page={page}
						pageSize={pageSize}
						total={total}
					></TablePagination>
				</div>
			)}
		</div>
	);
}
