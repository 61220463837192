import { Button, Typography, TextField } from '@barracuda-internal/bds-core';
import React, { ReactElement,useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from 'src/styles/di-theme';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { useApiRequest } from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import { useHistory } from 'react-router-dom';

interface Props {}

function AccountUpdate({}: Props): ReactElement {
	
	const history = useHistory();
	const classes = useStyles();
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const [accountName,setAccountName] = useState(auth.accountDetails.account.name);
	const [doUpdate, setDoUpdate] = useState(false);
	const [loading,setLoading] = useState(false);
	const [updateStatus, req, error] = useApiRequest(
		doUpdate &&
			(() => {
				setLoading(true);
				let body = [{ "op": "replace", "path": "/name", "value": accountName }]
				return api
					.updateAccount(
						auth.accountDetails.account.id,
						body
					)
					.then((res) => {
						history.go(0);
						setLoading(false);
					})
					.catch((res) => {
						setLoading(false);
					}).finally(() => {
						setDoUpdate(false);
					})
			}),
		[doUpdate],
		() => {},
	);
	const handleAccountNameChange = (e) => {
        setAccountName(e.target.value)
	};
	return (
		<div>
			<Typography
				variant="h3"
				color="textPrimary"
				className={classes['mb_4']}
			>
				Tenant Information
			</Typography>

			<div className={classes['ml_2']}>
				
				<Grid container spacing={1}>
				<Grid item xs={9}>
					<TextField
						fullWidth
						size="small"
						label="Tenant Name"
						required
						onChange={handleAccountNameChange}
						value={accountName}
						variant="outlined"
						inputProps={{ maxLength: 30 }}
					/>
					</Grid>
					<Grid item xs={3}>
						<Button
							variant="outlined"
							onClick={() => setDoUpdate(true)}
							color="secondary"
						>
							Update
						</Button>
					</Grid>
					
				</Grid>
			</div>
		</div>
	);
}

export default AccountUpdate;
