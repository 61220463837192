import { Alert } from '@material-ui/lab';
import React, { ReactElement, useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Typography, Button } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';

function RequestPIConsent({ setStep, activeStep }): ReactElement {
	const [consent, setConsent] = useState(true);
	const [doConsent, setDoConsent] = useState(false);
	const [first, setFirst] = useState(false);
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [updateStatus, req, error] = useApiRequest(
		doConsent &&
			(() =>
				api.updateUserConfig('current', [
					{
						path: '/personalInfoCollectionConsented',
						op: 'replace',
						value: consent,
					},
				])),
		[doConsent],
		() => {
			localStorage.setItem('is_setup','true')
			setStep(activeStep + 1);
			setDoConsent(false);
		},
	);

	useApiRequest(
		(signal) => api.getLoggedInUser({ signal }),
		[first],
		(resp) => {
			let user = resp[1];
			setConsent(user.config.personalInfoCollectionConsented);
		},
	);

	let handleChange = (e, checked) => {
		setConsent(checked);
	};
	let handleNext = () => {
		setDoConsent(true);
	};

	return (
		<div>
			<Typography
				variant="h1"
				color="textPrimary"
				gutterBottom
				className={classes['mt_4']}
			>
				Personal Information consent
			</Typography>
			{error ? (
				<Alert color="error">
					There was an error saving the form. Please try again.
				</Alert>
			) : null}
			{!consent ? (
				<Alert severity="warning">
					<Typography
						variant="body2"
						color="textPrimary"
						className={classes['mb_1']}
					>
						You did not consent to the Barracuda personal
						information agreement. You may miss out on full product
						support and communications.
					</Typography>
					<Typography
						variant="body2"
						color="textPrimary"
						className={classes['mb_1']}
					>
						You can update your consent under Data settings later or
						accept now.
					</Typography>
				</Alert>
			) : null}
			<div className={classes['mt_4']}>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<FormControlLabel
							control={
								<Switch
									name="checkedB"
									color="primary"
									onChange={handleChange}
									checked={consent}
								/>
							}
							label="Accepted"
						/>
					</Grid>
					<Grid item xs={9}>
						<Typography
							variant="body1"
							color="textPrimary"
							className={classes['mb_2']}
						>
							Barracuda Data Inspector can collect your name and
							email address for the purpose of providing product
							support and communicating product news and
							announcements.
						</Typography>
						<Typography
							variant="body1"
							color="textPrimary"
							className={classes['mb_2']}
						>
							Please see our{' '}
							<a
								href="https://www.barracuda.com/company/legal/trust-center/data-privacy"
								target="_blank"
							>
								Trust Center
							</a>{' '}
							for information on how we keep your data secure.
						</Typography>
					</Grid>
				</Grid>
				<div className={classes['mt_3']}>
					<Grid container direction="row" spacing={1}>
						<Grid item xs={8}></Grid>
						<Grid item xs={2}>
							<Button
								onClick={() => setStep(activeStep - 1)}
								color="primary"
							>
								Back
							</Button>
						</Grid>
						<Grid item xs={2}>
							<Button
								color="primary"
								onClick={handleNext}
								variant="contained"
							>
								Next
							</Button>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	);
}

export default RequestPIConsent;
