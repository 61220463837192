import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    TextField

} from '@barracuda-internal/bds-core';

import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
export default function AddAccount({
    showAdd = false,
    companyName,
    companyId,
    closePopup,
}) {
    const classes = useStyles();
    const { api } = useAppToolchain();
    const [accountName, setAccountName] = useState('');
    const [doAdd, setDoAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateStatus, req, error] = useApiRequest(
        doAdd &&
        (() => {
            setLoading(true);

            return api
                .addAccountToCompany(
                    companyId, accountName
                )
                .then((res) => {
                   
                    setAccountName('');
                    close(true);
                })
                .catch((res) => {
                    
                }).finally(()=>{
                    setLoading(false);
                })
        }),
        [doAdd],
        () => { },
    );
    const close = (refresh) => {
        setAccountName('');
        setDoAdd(false)
        closePopup(refresh);
       
    }

    const handleAccountNameChange = (e) => {
        setAccountName(e.target.value)

    };

    return (
        <div>
            <Dialog open={showAdd} onClose={() => { }} PaperProps={{
                style: {
                    width: '500px', 
                    height: '250px',
                    maxWidth: '500px', 
                    maxHeight: '250px',
                },
            }}>
                <DialogTitle>
                    <Typography variant="h2">
                        Add Tenant for {companyName}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        size="small"
                        label="Tenant Name"
                        required
                        onChange={handleAccountNameChange}
                        value={accountName}
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                    />

                </DialogContent>
                <DialogActions className={classes['m_1']}>
                    <Button
                        disabled={loading}
                        color="primary"
                        onClick={() => {
                            setLoading(false);
                            close(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        isLoading={loading}
                        disabled={accountName == ''}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setDoAdd(true);
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
